import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=0461dfa1&"
import script from "./Sidebar.vue?vue&type=script&lang=js&"
export * from "./Sidebar.vue?vue&type=script&lang=js&"
import style0 from "./Sidebar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Link: require('/tmp/build_1c74dfa1/components/link/Link.vue').default,ListHeader: require('/tmp/build_1c74dfa1/components/list/ListHeader.vue').default,ListSideAlbum: require('/tmp/build_1c74dfa1/components/list/ListSideAlbum.vue').default,ListArticle: require('/tmp/build_1c74dfa1/components/list/ListArticle.vue').default,ListNews: require('/tmp/build_1c74dfa1/components/list/ListNews.vue').default})
