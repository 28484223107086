import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=4224a4af&"
import script from "./Article.vue?vue&type=script&lang=js&"
export * from "./Article.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TagList: require('/tmp/build_1c74dfa1/components/tag/TagList.vue').default,ShareButtons: require('/tmp/build_1c74dfa1/components/share/ShareButtons.vue').default,Button: require('/tmp/build_1c74dfa1/components/button/Button.vue').default,Link: require('/tmp/build_1c74dfa1/components/link/Link.vue').default,ShareBar: require('/tmp/build_1c74dfa1/components/share/ShareBar.vue').default})
