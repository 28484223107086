import { render, staticRenderFns } from "./ListNews.vue?vue&type=template&id=985b1d9e&"
import script from "./ListNews.vue?vue&type=script&lang=js&"
export * from "./ListNews.vue?vue&type=script&lang=js&"
import style0 from "./ListNews.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ListNewsItem: require('/tmp/build_1c74dfa1/components/list/ListNewsItem.vue').default,ListLinkMore: require('/tmp/build_1c74dfa1/components/list/ListLinkMore.vue').default})
