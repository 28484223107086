import { render, staticRenderFns } from "./ArticleContent4.vue?vue&type=template&id=59d39ac5&"
import script from "./ArticleContent4.vue?vue&type=script&lang=js&"
export * from "./ArticleContent4.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Heading: require('/tmp/build_1c74dfa1/components/heading/Heading.vue').default,ListGuest: require('/tmp/build_1c74dfa1/components/list/ListGuest.vue').default})
