import { render, staticRenderFns } from "./error.vue?vue&type=template&id=5e894016&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/tmp/build_1c74dfa1/components/button/Button.vue').default,Link: require('/tmp/build_1c74dfa1/components/link/Link.vue').default,Article: require('/tmp/build_1c74dfa1/components/article/Article.vue').default,Sidebar: require('/tmp/build_1c74dfa1/components/Sidebar.vue').default,PageWrap: require('/tmp/build_1c74dfa1/components/PageWrap.vue').default})
